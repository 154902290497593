import * as React from "react";
import classNames from "classnames";
import { LinkedinIcon } from "../images/svgs";

// markup
const TeamMember = ({ children, className = "", name, position, linkedin }) => {
  return (
    <div className={classNames("team-member", className)}>
      <div>
        <figure>{children}</figure>
        <h3>
          <b>{name}</b>
        </h3>
        <span>{position}</span>
        <div>
          <a href={linkedin}>
            <LinkedinIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
