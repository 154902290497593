import * as React from "react";

// import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { StaticImage } from "gatsby-plugin-image";
import Page from "../components/Layout/Page";
import Container from "../components/Layout/Container";
import BtnFilled from "../components/reusable/BtnFilled";
import TeamMember from "../components/TeamMember";
import HubspotForm from "react-hubspot-form";
import Seo from "../components/reusable/Seo";

// markup
const AboutPage = () => {
  // const breakpoints = useBreakpoint();
  return (
    <Page className={"about-us"}>
      <Seo
        title="About Inviz AI"
        path="about-inviz-ai-solutions"
        description="We build End-to-End AI powered solutions for
        mission-critical applications at scale.We enable companies of all sizes, by building AI-ML solutions that
        solve core business problems."
      />
      <section className="hero">
        <Container>
          <h1>
            <span>
              We build <i>End-to-End AI</i> powered solutions for
              mission-critical applications at scale.
            </span>
          </h1>
        </Container>
      </section>
      <section className="overview">
        <Container>
          <header>
            <h2>
              <b>Our Story</b>
            </h2>
            <p>
              Inviz was founded in 2019 to enable companies to solve core
              business problems using Artificial Intelligence. Today Inviz is a
              growth-stage start-up with over 110+ team members/employees and
              some of the biggest companies in the portfolio.
            </p>
          </header>
          <br />
          <div className="impact">
            <div>
              <strong>
                $250<i>M+</i>
              </strong>
              <span>Revenue Unlocked</span>
            </div>
            <div>
              <strong>
                200<i>M+</i>
              </strong>
              <span>Users Impacted</span>
            </div>
            <div>
              <strong>4</strong>
              <span>FORTUNE 500 CLIENTS</span>
            </div>
            {/*
            <div>
              <strong>
                50<i>+</i>
              </strong>
              <span>Models Deployed</span>
            </div>
            */}
          </div>
        </Container>
      </section>
      <section className="vision">
        <Container>
          <h2>
            <strong>Our Vision</strong>
          </h2>
          <p>
            Inviz is AI-first technology company driven by the desire to
            democratize AI and transform businesses
          </p>
        </Container>
      </section>

      <section className="values">
        <Container>
          <header>
            <h2>
              <b>Our Values</b>
            </h2>
          </header>
          <div className="text">
            <div className="value">
              <div>
                <figure>
                  <StaticImage
                    src="./../images/about/values/excellence.png"
                    alt="Excellence"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="none"
                  />
                </figure>
                <div>
                  <h3>
                    <b>Excellence</b>
                  </h3>
                  <p>
                    We take pride in the quality of solutions we deliver. We
                    constantly strive to deliver best solutions in on time,
                    ensuring maximum business value.
                  </p>
                </div>
              </div>
            </div>
            <div className="value">
              <div>
                <figure>
                  <StaticImage
                    src="./../images/about/values/audience.png"
                    alt="Customer Centricity"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="none"
                  />
                </figure>
                <div>
                  <h3>
                    <b>Customer Centricity</b>
                  </h3>

                  <p>
                    We succeed only if our customers succeed. We put customers
                    at the core of everything we do.
                  </p>
                </div>
              </div>
            </div>
            <div className="value">
              <div>
                <figure>
                  <StaticImage
                    src="./../images/about/values/empathy.png"
                    alt="Empathy"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="none"
                  />
                </figure>
                <div>
                  <h3>
                    <b>Empathy</b>
                  </h3>
                  <p>
                    We put ourselves in customer’s shoes to understand their
                    aspirations and pain points. This enables us to navigate
                    different problems and identify suitable solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="leadership">
        <Container>
          <h2>
            <b>Our Leadership</b>
          </h2>
          <div className="members">
            <TeamMember
              name="Priyabrata Kuanr"
              position="Founder &amp; CEO"
              linkedin="https://www.linkedin.com/in/priyabrata-kuanr/"
            >
              <figure>
                <StaticImage
                  src="./../images/team/priyabrata-kuanr-ceo.png"
                  alt="Priyabrata Kuanr - Founder and CEO, Inviz AI Solutions"
                  layout="fullWidth"
                  loading="lazy"
                />
              </figure>
            </TeamMember>
            {/*
            <TeamMember
              name="Manish Shukla"
              position="Chief Technology Officer"
              linkedin="https://www.linkedin.com/in/ms-4126957/"
            >
              <figure>
                <StaticImage
                  src="./../images/team/manish.jpg"
                  // src="./../images/careers/careers-at-inviz-values.jpg"
                  alt="Manish Shukla - Chief Technology Officer, Inviz AI Solutions"
                  layout="fullWidth"
                  loading="lazy"
                />
              </figure>
            </TeamMember>
          */}
            <TeamMember
              name="Kannan Ranganathan"
              position="Executive Director &amp; Chief Product Officer"
              linkedin="https://www.linkedin.com/in/kannan-ranganathan/"
            >
              <figure>
                <StaticImage
                  src="./../images/team/Kannan-Ranganathan.jpg"
                  alt="Kannan Ranganathan - Executive Director & Chief Product Officer, Inviz AI Solutions"
                  layout="fullWidth"
                  loading="lazy"
                />
              </figure>
            </TeamMember>
            {/*
            <TeamMember
              name="Ashwin Chandrashekar"
              position="Chief Client Success &amp; Growth Officer"
              linkedin="https://www.linkedin.com/in/ashwinchandrasekar/"
            >
              <figure>
                <StaticImage
                  src="./../images/team/Ashwin-.jpg"
                  alt="Ashwin Chandrashekar - Chief Client Success & Growth Officer, Inviz AI Solutions"
                  layout="fullWidth"
                  loading="lazy"
                />
              </figure>
            </TeamMember>
        */}

            {/*
            <TeamMember
              name="Anurag Kumar"
              position="VP Marketing"
              linkedin="https://www.linkedin.com/in/anuragkmr/"
            >
              <figure>
                <StaticImage
                  src="./../images/team/anurag-kumar.jpg"
                  alt="Anurag Kumar - VP Marketing, Inviz AI Solutions"
                  layout="fullWidth"
                  loading="lazy"
                />
              </figure>
            </TeamMember>
      */}

            <TeamMember
              name="Shridhar Nayak"
              position="Chief Operating Officer"
              linkedin="https://www.linkedin.com/in/shridhar-nayak-152179204/"
            >
              <figure>
                <StaticImage
                  src="./../images/team/shridhar.jpg"
                  alt="Shridhar Nayak - Chief Operating Officer, Inviz AI Solutions "
                  layout="fullWidth"
                  loading="lazy"
                />
              </figure>
            </TeamMember>
          </div>
        </Container>
      </section>
      <section className="contact-form">
        <Container>
          <header>
            <h2>
              <b>Let's Talk</b>
            </h2>
          </header>
          <div className="form-wrapper">
            <p>
              Let’s talk about how Inviz can help you solve your business
              problems
            </p>
            <br />
            <div className="form-wrapper-inner">
              <HubspotForm
                portalId="7995340"
                formId="82e9d7f6-85fb-4ae5-9e01-f8ee923615a3"
                onSubmit={() => console.log("Submit!")}
                onReady={(form) => console.log("Form ready!")}
                loading={<div>Loading...</div>}
              />
            </div>
          </div>
        </Container>
      </section>
      <section className="career">
        <Container>
          <div className="text">
            <h2>
              <b>Join Inviz</b>
            </h2>
            <p>
              Do you like working in a flat-hierarchy? Are you looking to work
              in a start-up culture and solve core business problems? If the
              answer is yes, we have exciting career opportunities for you. {" "}
            </p>

            <div className="cta">
              <BtnFilled
                to="https://careers.inviz.ai/jobs/Careers"
                color="white"
                context="external"
              >
                See Open Positions
              </BtnFilled>
            </div>
          </div>
        </Container>
      </section>
    </Page>
  );
};

export default AboutPage;
